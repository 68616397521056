<template>
  <div>
    <div class="d-flex justify-end">
      <v-btn color="primary" small @click="addMembership">
        <v-icon small left>mdi-plus</v-icon>
        Novo
      </v-btn>
    </div>
    <v-alert v-if="!memberships.length" color="info" class="mt-4 mb-0" text dense>
      <p class="text-18 font-weight-bold mb-0">
        Sua organização ainda não possui filiações
      </p>
      <p class="mb-0">
        As filiações são exibidas aqui. Crie uma nova filiação clicando no botão "Novo"
      </p>
    </v-alert>
    <v-row v-else>
      <v-col
        cols="12"
        sm="6"
        md="4"
        v-for="membership in membershipsMapped"
        :key="membership.id"
      >
        <v-card
          class="white--text"
          :style="{
            borderRadius: '10px',
            border: `3px solid ${membership.backgroundColor || '#000'}`,
            boxShadow: `0 0 10px  ${
              membership.backgroundColor || 'rgba(0,0,0,.2)'
            }`,
            background: `url('${membership.backgroundImg}'), ${
              membership.backgroundColor || '#000'
            }`,
            backgroundSize: 'cover',
          }"
        >
          <div
            class="d-flex flex-column justify-space-between h-full pa-4 gap-7"
            style="background: rgba(0, 0, 0, 0.7)"
          >
            <div class="d-flex gap-4">
              <h3 class="white--text mb-0">{{ membership.name }}</h3>
            </div>
            <div class="d-flex flex-column gap-2">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div class="d-flex align-center gap-4" v-on="on">
                    <v-icon color="yellow" v-bind="attrs">mdi-star</v-icon>
                    <h5 class="white--text mb-0">
                      {{ membership.benefits.length }} beneficio{{
                        membership.benefits.length != 1 ? "s" : ""
                      }}
                    </h5>
                  </div>
                </template>
                <div class="d-flex flex-column">
                  <p
                    v-for="benefit in membership.benefits"
                    :key="benefit.id"
                    class="mb-0"
                  >
                    <b class="font-weight-bold">{{ benefit }}</b>
                  </p>
                </div>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="d-flex align-center gap-4"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="yellow">
                      mdi-credit-card-settings-outline
                    </v-icon>
                    <h5 class="white--text mb-0">
                      {{ membership.Plans.length }} plano{{
                        membership.Plans.length != 1 ? "s" : ""
                      }}
                    </h5>
                  </div>
                </template>
                <div class="d-flex flex-column">
                  <p
                    v-for="plan in membership.Plans"
                    :key="plan.id"
                    class="mb-0 d-flex align-center gap-2"
                  >
                    <b class="font-weight-bold">{{ plan.price | currency }}</b>
                    <small>{{ plan | membershipDuration }}</small>
                  </p>
                </div>
              </v-tooltip>
            </div>
            <div class="d-flex justify-space-between align-end">
              <v-chip label :color="membership.status.color">
                {{ membership.status.text }}
              </v-chip>
              <v-btn icon color="white" @click="editMembership(membership)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <membership-create @success="getMembership" ref="add-membership" />
  </div>
</template>

<script>
import MembershipCreate from "./modals/MembershipCreate.vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  components: { MembershipCreate },
  data: () => ({
    loading: true,
  }),
  methods: {
    ...mapActions("organization", ["updateMemberships"]),
    editMembership(membership) {
      this.$refs["add-membership"].open(membership);
    },
    addMembership() {
      this.$refs["add-membership"].open();
    },
    getMembership() {
      this.updateMemberships();
    },
  },
  computed: {
    ...mapGetters("organization", ["memberships"]),
    membershipsMapped() {
      return this.memberships.map((membership) => {
        const status = !membership.active
          ? { text: "Inativo", color: "gray" }
          : !membership.endDate || membership.endDate > new Date()
          ? { text: "Ativo", color: "success" }
          : { text: "Expirado", color: "error" };

        const benefits = membership.description.split("\n").filter((b) => b);

        return { ...membership, status, benefits };
      });
    },
  },
};
</script>

<style></style>
